import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { tablet, useQuery } from 'styles/breakpoints';
import { DynamicImage, PrimaryButton } from 'components';

interface ResultsPersonalizedTypes {
  list: {
    title: string;
    text: string;
    icon: string;
  }[];
  buttonTitle: string;
  onContinue: () => void;
}

const ResultsPlanInfo: FC<ResultsPersonalizedTypes> = ({
  list,
  buttonTitle,
  onContinue,
}) => {
  const [loading, setLoading] = useState(false);

  const nextStep = () => {
    setLoading(true);
    onContinue();
  };

  return (
    <Container>
      <ListContainer>
        {list.map((item, index) => (
          <ListItem key={index}>
            <ImgContainer>
              <Img src={item?.icon} alt="" />
            </ImgContainer>
            <TextContainer>
              <ListTitle>{item.title}</ListTitle>
              <ListText>{item.text}</ListText>
            </TextContainer>
          </ListItem>
        ))}
      </ListContainer>

      <ButtonContainer>
        <ContinueButtonStyled
          onClick={nextStep}
          loading={loading}
          disabled={loading}
        >
          {buttonTitle}
        </ContinueButtonStyled>
      </ButtonContainer>
    </Container>
  );
};

export default ResultsPlanInfo;

const Img = styled(DynamicImage)`
  width: 1.5rem;
  height: 1.5rem;
`;

const ImgContainer = styled.section`
  width: 1.5rem;
  height: 1.5rem;
`;

const ListContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.75rem;
  padding-top: 1rem;
  @media ${tablet} {
    padding: 1rem 0 2rem;
  }
`;

const ListItem = styled.section`
  display: flex;
  align-items: flex-start;
  gap: 0.75rem;
  border-radius: 0.625rem;
  border: 1px solid rgba(0, 0, 0, 0.08);
  background: #f9f9f9;
  padding: 0.75rem;
`;

const ListTitle = styled.h4`
  color: #141515;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  @media ${tablet} {
  }
`;

const ListText = styled.p`
  color: #727373;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  @media ${tablet} {
  }
`;

const TextContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

const Container = styled.div`
  max-width: 28.75rem;
  margin: 0 auto;
  width: 100%;
`;

const ButtonContainer = styled.section`
  width: 100%;
  @media ${tablet} {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem 1rem;
    width: 100%;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 80%;
      background: #ffffff;
      filter: blur(4px);
      z-index: -1;
    }
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 80%;
      background: #ffffff;
      z-index: -1;
    }
  }
`;

const ContinueButtonStyled = styled(PrimaryButton)`
  margin: 1.5rem auto 0;
  @media ${tablet} {
    max-width: 28.75rem;
    margin: 0 auto;
  }
`;
